<template>
  <div class="app-container">
    <img :src="pageInfo[currentIndex].banner" alt="" srcset="" class="banner" />
    <el-tabs v-model="activeName">
      <!-- 1.功能概述 -->
      <el-tab-pane name="1st">
        <span slot="label">
          <div class="tab-label">
            <img
              v-if="activeName == '1st'"
              src="~@/assets/productCenter/common/tab1.png"
              alt=""
            />
            <img
              v-else
              src="~@/assets/productCenter/common/tab1-b.png"
              alt=""
            />

            功能概述
          </div>
        </span>

        <div class="tab-content functions-overview">
          <div class="intro">
            <div class="title">{{ pageInfo[currentIndex].title }}</div>
            <div class="divide"></div>
            <div class="desc">{{ pageInfo[currentIndex].desc }}</div>
            <img :src="pageInfo[currentIndex].main" alt="" class="main-pic" />
            <div class="design-title">功能介绍</div>
            <div class="design-decoration"></div>
            <div class="function-wrapper">
              <div
                class="function-item"
                v-for="item in pageInfo[currentIndex].functions"
                :key="item.img"
              >
                <img :src="item.img" alt="" />
                <div class="function-title">{{ item.title }}</div>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>

      <!-- 2.软件架构（暂无 -->
      <el-tab-pane name="2nd">
        <span slot="label">
          <div class="tab-label">
            <img
              v-if="activeName == '2nd'"
              src="~@/assets/productCenter/common/tab2.png"
              alt=""
            />
            <img
              v-else
              src="~@/assets/productCenter/common/tab2-b.png"
              alt=""
            />软件架构
          </div>
        </span>

        <div class="tab-content software"></div>
      </el-tab-pane>

      <!-- 3.硬件方案 -->
      <el-tab-pane name="3rd">
        <span slot="label">
          <div class="tab-label">
            <img
              v-if="activeName == '3rd'"
              src="~@/assets/productCenter/common/tab3.png"
              alt=""
            />
            <img
              v-else
              src="~@/assets/productCenter/common/tab3-b.png"
              alt=""
            />硬件方案
          </div>
        </span>

        <div class="tab-content hardware">
          <div class="hard-wrapper">
            <div
              class="hard-item-wrapper"
              v-for="item in pageInfo[currentIndex].hardware"
              :key="item.img"
            >
              <div class="hard-item">
                <img :src="item.img" alt="" />
                <div class="hard-title">{{ item.title }}</div>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>

      <!-- 4.项目展示 -->
      <el-tab-pane name="4th">
        <span slot="label">
          <div class="tab-label">
            <img
              v-if="activeName == '4th'"
              src="~@/assets/productCenter/common/tab4.png"
              alt=""
            />
            <img
              v-else
              src="~@/assets/productCenter/common/tab4-b.png"
              alt=""
            />项目展示
          </div>
        </span>

        <div class="tab-content project">
          <div class="project-wrapper">
            <div
              class="project-item-wrapper"
              v-for="item in pageInfo[currentIndex].screenShots"
              :key="item.img"
            >
              <div class="project-item">
                <img :src="item.img" alt="" />
                <div class="project-title">{{ item.title }}</div>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  mounted() {
    console.log(this.$route.query);
    this.currentIndex = this.$route.query.product;
  },

  watch: {
    $route(to) {
      console.log(to);
      this.currentIndex = to.query.product;
      this.activeName = "1st";
    },
  },

  data() {
    return {
      activeName: "1st",
      currentIndex: 0,
      pageInfo: [
        {
          banner: require("../../assets/productCenter/pro-1/banner.png"),
          title: "环境监控系统",
          desc: "基于各大实验室、工厂、工地等场所对环境指标要求极高，该系统对环境各项指标进行实时监测，并根据历史数据，生成环境报告，协助管理者做出判断，进行预防控制。如发生异常，实时报警，及时做出应急措施。",
          main: require("../../assets/productCenter/pro-1/main.png"),
          functions: [
            {
              title: "实时监测",
              img: require("../../assets/productCenter/pro-1/f1.png"),
            },
            {
              title: "历史数据",
              img: require("../../assets/productCenter/pro-1/f2.png"),
            },
            {
              title: "统计分析",
              img: require("../../assets/productCenter/pro-1/f3.png"),
            },
            {
              title: "异常预警",
              img: require("../../assets/productCenter/pro-1/f4.png"),
            },
            {
              title: "照明控制",
              img: require("../../assets/productCenter/pro-1/f5.png"),
            },
            {
              title: "报告生成",
              img: require("../../assets/productCenter/pro-1/f6.png"),
            },
            {
              title: "智慧大屏",
              img: require("../../assets/productCenter/pro-1/f7.png"),
            },
            {
              title: "自定义监测",
              img: require("../../assets/productCenter/pro-1/f8.png"),
            },
            {
              title: "设备列表",
              img: require("../../assets/productCenter/pro-1/f9.png"),
            },
            {
              title: "空调控制",
              img: require("../../assets/productCenter/pro-1/f10.png"),
            },
          ],
          hardware: [
            {
              title: "温湿度传感器",
              img: require("../../assets/productCenter/pro-1/hard1.png"),
            },
            {
              title: "气压传感器",
              img: require("../../assets/productCenter/pro-1/hard2.png"),
            },
            {
              title: "有毒气体传感器",
              img: require("../../assets/productCenter/pro-1/hard3.png"),
            },
            {
              title: "智能插座",
              img: require("../../assets/productCenter/pro-1/hard4.png"),
            },
            {
              title: "智能开关",
              img: require("../../assets/productCenter/pro-1/hard5.png"),
            },
            {
              title: "门禁系统",
              img: require("../../assets/productCenter/pro-1/hard6.png"),
            },
            {
              title: "摄像头",
              img: require("../../assets/productCenter/pro-1/hard7.png"),
            },
          ],
          screenShots: [
            {
              title: "PCR智慧实验室",
              img: require("../../assets/productCenter/pro-1/project1.png"),
            },
            {
              title: "安奈苏州实验室",
              img: require("../../assets/productCenter/pro-1/project2.png"),
            },
          ],
        },
        {
          banner: require("../../assets/productCenter/pro-2/banner.png"),
          title: "能耗监控系统",
          desc: "能耗监控系统可实现对建筑内电、水等能耗实时分类、分项、分部门精确计量，计量数据远程传输；数据采集与存储，数据统计与分析，数据发布与远传。 以实时监测能源数据为依据，为建筑业主实现有效节能，并提高建筑能源的自动化管理水平，为业主的节能研究、设计与建设（改造）提供参考依据。",
          main: require("../../assets/productCenter/pro-2/main.png"),
          functions: [
            {
              title: "用能概览",
              img: require("../../assets/productCenter/pro-2/f1.png"),
            },
            {
              title: "历史数据",
              img: require("../../assets/productCenter/pro-2/f2.png"),
            },
            {
              title: "区域耗能",
              img: require("../../assets/productCenter/pro-2/f3.png"),
            },
            {
              title: "异常预警",
              img: require("../../assets/productCenter/pro-2/f4.png"),
            },
            {
              title: "能耗报告",
              img: require("../../assets/productCenter/pro-2/f5.png"),
            },
            {
              title: "产量分析",
              img: require("../../assets/productCenter/pro-2/f6.png"),
            },
            {
              title: "智慧大屏",
              img: require("../../assets/productCenter/pro-2/f7.png"),
            },
            {
              title: "比较分析",
              img: require("../../assets/productCenter/pro-2/f8.png"),
            },
            {
              title: "设备列表",
              img: require("../../assets/productCenter/pro-2/f9.png"),
            },
            {
              title: "系统设置",
              img: require("../../assets/productCenter/pro-2/f10.png"),
            },
          ],
          hardware: [
            {
              title: "温湿度传感器",
              img: require("../../assets/productCenter/pro-2/hard1.png"),
            },
            {
              title: "气压传感器",
              img: require("../../assets/productCenter/pro-2/hard2.png"),
            },
          ],
          screenShots: [
            {
              title: "天隆智慧工厂",
              img: require("../../assets/productCenter/pro-2/project1.png"),
            },
            {
              title: "安奈苏州实验室",
              img: require("../../assets/productCenter/pro-2/project2.png"),
            },
          ],
        },
        {
          banner: require("../../assets/productCenter/pro-3/banner.png"),
          title: "人员管理系统",
          desc: "人员管理系统可以对员工名单、权限、考勤等进行管理，也可以对人员进行实时定位，查看员工活动轨迹。对特殊区域进行人员的自定义限制，如超越权限可以实时报警等。",
          main: require("../../assets/productCenter/pro-3/main.png"),
          functions: [
            {
              title: "人员列表",
              img: require("../../assets/productCenter/pro-3/f1.png"),
            },
            {
              title: "权限管理",
              img: require("../../assets/productCenter/pro-3/f2.png"),
            },
            {
              title: "部门管理",
              img: require("../../assets/productCenter/pro-3/f3.png"),
            },
            {
              title: "报警管理",
              img: require("../../assets/productCenter/pro-3/f4.png"),
            },
            {
              title: "人员定位",
              img: require("../../assets/productCenter/pro-3/f5.png"),
            },
            {
              title: "区域人数统计",
              img: require("../../assets/productCenter/pro-3/f6.png"),
            },
            {
              title: "智慧大屏",
              img: require("../../assets/productCenter/pro-3/f7.png"),
            },
            {
              title: "人员轨迹查询",
              img: require("../../assets/productCenter/pro-3/f8.png"),
            },
            {
              title: "设备列表",
              img: require("../../assets/productCenter/pro-3/f9.png"),
            },
            {
              title: "考勤管理",
              img: require("../../assets/productCenter/pro-3/f10.png"),
            },
          ],
          hardware: [
            {
              title: "RFID基站",
              img: require("../../assets/productCenter/pro-3/hard1.png"),
            },
            {
              title: "RFID标签",
              img: require("../../assets/productCenter/pro-3/hard2.png"),
            },
            {
              title: "门禁系统",
              img: require("../../assets/productCenter/pro-3/hard3.png"),
            },
          ],
          screenShots: [
            {
              title: "PCR智慧实验室",
              img: require("../../assets/productCenter/pro-3/project1.png"),
            },
            {
              title: "安奈苏州实验室",
              img: require("../../assets/productCenter/pro-3/project2.png"),
            },
          ],
        },
        {
          banner: require("../../assets/productCenter/pro-4/banner.png"),
          title: "视频监控系统",
          desc: "视频监控系统由实时控制系统、监视系统及管理信息系统组成。实时控制系统完成实时数据采集处理、存储、反馈的功能；监视系统完成对各个监控点的全天候的监视，能在多操作控制点上切换多路图像；管理信息系统完成各类所需信息的采集、接收、传输、加工、处理，是整个系统的控制核心。视频监控以其直观、方便、信息内容丰富而广泛应用于许多场合。",
          main: require("../../assets/productCenter/pro-4/main.png"),
          functions: [
            {
              title: "实时监控",
              img: require("../../assets/productCenter/pro-4/f1.png"),
            },
            {
              title: "多画面展示",
              img: require("../../assets/productCenter/pro-4/f2.png"),
            },
            {
              title: "历史回放",
              img: require("../../assets/productCenter/pro-4/f3.png"),
            },
            {
              title: "报警管理",
              img: require("../../assets/productCenter/pro-4/f4.png"),
            },
            {
              title: "摄像头管理",
              img: require("../../assets/productCenter/pro-4/f5.png"),
            },
            {
              title: "语音通话",
              img: require("../../assets/productCenter/pro-4/f6.png"),
            },
            {
              title: "智慧大屏",
              img: require("../../assets/productCenter/pro-4/f7.png"),
            },
          ],
          hardware: [
            {
              title: "摄像头",
              img: require("../../assets/productCenter/pro-4/hard1.png"),
            },
            {
              title: "中控机",
              img: require("../../assets/productCenter/pro-4/hard2.png"),
            },
          ],
          screenShots: [
            {
              title: "安奈苏州实验室",
              img: require("../../assets/productCenter/pro-4/project1.png"),
            },
          ],
        },
        {
          banner: require("../../assets/productCenter/pro-5/banner.png"),
          title: "仓储管理系统",
          desc: "仓库管理系统利用条形码对仓储各环节实施全过程控制管理，并可对货物进行入库、出库、货位、批次等实现条形码标签序列号管理，对整个收货、发货、等各个环节的规范化作业, 还可以根据客户的需求制作多种合理的统计报表。条码技术与信息技术的结合帮助企业合理有效地利用仓库空间，以快速、准确、低成本的方式为客户提供最好的服务。",
          main: require("../../assets/productCenter/pro-5/main.png"),
          functions: [
            {
              title: "采购管理",
              img: require("../../assets/productCenter/pro-5/f1.png"),
            },
            {
              title: "发票管理",
              img: require("../../assets/productCenter/pro-5/f2.png"),
            },
            {
              title: "货物信息管理",
              img: require("../../assets/productCenter/pro-5/f3.png"),
            },
            {
              title: "库存管理",
              img: require("../../assets/productCenter/pro-5/f4.png"),
            },
            {
              title: "出入库管理",
              img: require("../../assets/productCenter/pro-5/f5.png"),
            },
            {
              title: "电视大屏",
              img: require("../../assets/productCenter/pro-5/f6.png"),
            },
          ],
          hardware: [
            {
              title: "扫码枪",
              img: require("../../assets/productCenter/pro-5/hard1.png"),
            },
            {
              title: "条形码",
              img: require("../../assets/productCenter/pro-5/hard2.png"),
            },
          ],
          screenShots: [
            {
              title: "安奈苏州实验室",
              img: require("../../assets/productCenter/pro-5/project1.png"),
            },
            {
              title: "正邦管理系统",
              img: require("../../assets/productCenter/pro-5/project2.png"),
            },
          ],
        },
        {
          banner: require("../../assets/productCenter/pro-6/banner.png"),
          title: "采样管理系统",
          desc: "系统可以实现样本采样过程自动化，无纸化，样本检验结果数据同步自动化，建立样本大数据基础设施。解决传统样本人工混样问题，提高检测能力水平。将扫码系统、采样管、混样仪器、提取仪、荧光定量 PCR 仪全部接入数据系统，实现从采样到 出结果全流程监控的智能管理平台。",
          main: require("../../assets/productCenter/pro-6/main.png"),
          functions: [
            {
              title: "采样扫码",
              img: require("../../assets/productCenter/pro-6/f1.png"),
            },
            {
              title: "任务管理",
              img: require("../../assets/productCenter/pro-6/f2.png"),
            },
            {
              title: "人员管理",
              img: require("../../assets/productCenter/pro-6/f3.png"),
            },
            {
              title: "猪舍管理",
              img: require("../../assets/productCenter/pro-6/f4.png"),
            },
            {
              title: "二维码管理",
              img: require("../../assets/productCenter/pro-6/f5.png"),
            },
            {
              title: "采样分析",
              img: require("../../assets/productCenter/pro-6/f6.png"),
            },
            {
              title: "样本信息管理",
              img: require("../../assets/productCenter/pro-6/f7.png"),
            },
            {
              title: "仓储管理",
              img: require("../../assets/productCenter/pro-6/f8.png"),
            },
            {
              title: "异常预警",
              img: require("../../assets/productCenter/pro-6/f9.png"),
            },
            {
              title: "数据报表",
              img: require("../../assets/productCenter/pro-6/f10.png"),
            },
          ],
          hardware: [
            {
              title: "扫码枪",
              img: require("../../assets/productCenter/pro-6/hard1.png"),
            },
            {
              title: "条形码",
              img: require("../../assets/productCenter/pro-6/hard2.png"),
            },
            {
              title: "GeneMix48 混样仪器",
              img: require("../../assets/productCenter/pro-6/hard3.png"),
            },
          ],
          screenShots: [
            {
              title: "S码扫码录入系统",
              img: require("../../assets/productCenter/pro-6/project1.png"),
            },
            {
              title: "S码扫码录入系统",
              img: require("../../assets/productCenter/pro-6/project2.png"),
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@function vw($px) {
  @return ($px / 1920) * 100vw;
}

.banner {
  width: 100%;
  height: vw(566);
  vertical-align: bottom;
}

.tab-label {
  display: flex;
  align-items: center;
  img {
    width: vw(22);
    height: vw(20);
    margin-right: vw(10);
  }
}

.tab-content {
  margin-top: vw(100);
  padding: 0 vw(370);
}

.functions-overview {
}

.software {
  height: vw(300);
}

.hardware {
  .hard-wrapper {
    display: flex;
    flex-wrap: wrap;

    .hard-item-wrapper {
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: vw(59);
      .hard-item {
        img {
          margin-bottom: vw(28);
          width: vw(264);
          height: vw(264);
          box-shadow: 0px 0px 20px 0px rgba(58, 54, 54, 0.1);
        }

        .hard-title {
          font-size: vw(14);
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }
}

.project {
  .project-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: vw(759);

    .project-item-wrapper {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: vw(59);
      .project-item {
        img {
          margin-bottom: vw(28);
          width: vw(568);
          height: vw(319);
          box-shadow: 0px 0px 10px 0px rgba(58, 54, 54, 0.1);
        }

        .project-title {
          font-size: vw(14);
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }
}

.intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-size: vw(24);
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    align-self: flex-start;
    margin-bottom: vw(30);
  }
  .divide {
    width: vw(1180);
    height: 1px;
    background: #dadada;
    margin-bottom: vw(32);
  }
  .desc {
    width: 100%;
    font-size: vw(14);
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    text-align: start;
    line-height: vw(30);
    margin-bottom: vw(30);
  }
  .main-pic {
    width: vw(680);
    height: vw(373);
    margin-bottom: vw(60);
  }
  .design-title {
    font-size: vw(16);
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    margin-bottom: vw(19);
  }
  .design-decoration {
    width: vw(34);
    height: vw(2);
    background: #ff9a4f;
    border-radius: 1px;
    align-self: center;
    margin-bottom: vw(60);
  }
  .function-wrapper {
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: vw(78);
    .function-item {
      display: flex;
      // width: 20%;
      width: vw(79);
      margin: 0 vw(96);
      // 中等屏幕
      @media screen and (max-width: 992px) {
        margin: 0;
      }

      // 极小型屏幕
      @media screen and (max-width: 600px) {
        margin: 0;
      }
      flex-direction: column;
      align-items: center;
      margin-bottom: vw(58);
      &:nth-child(5n + 1) {
        margin-left: 0;
      }
      &:nth-child(5n + 5) {
        margin-right: 0;
      }
      img {
        width: vw(79);
        height: vw(92);
        margin-bottom: vw(20);
      }
      .function-title {
        font-size: vw(14);
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
    }
  }
}

::v-deep .el-tabs__header {
  margin-bottom: 0;
  .el-tabs__nav-scroll {
    display: flex;
    justify-content: center;
    align-items: center;
    height: vw(80);
    background: #f9f9f9;
    .el-tabs__nav {
      height: 100%;
      .el-tabs__active-bar {
        height: vw(4);
        background-color: rgba(255, 130, 37, 1);
      }
      .el-tabs__item {
        padding: 0 vw(75);
        height: 100%;
        line-height: vw(80);
        color: rgba(51, 51, 51, 1);
        font-weight: bold;
        font-size: vw(16);
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
      .el-tabs__item.is-active {
        color: rgba(255, 130, 37, 1);
      }
    }
  }
}
</style>